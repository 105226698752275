<template>
  <div class="modal-container">
    <purchase-modal :invoice-style="invoiceStyle" />
  </div>
</template>

<script>
export default {
  components: {
    purchaseModal: () => ({
      component: import("../../../components/add/purchase/purchaseModal"),
    }),
  },
  data() {
    return {
      title: "",
      form: {
        documents: [],
        contractor: "",
        type: "product",
        vatRate: false,
      },
      disabledShowFile: false,
      listContractor: [
        { id: "", name: "Выбрать контрагента" },
        { id: "aqniet", name: "Aqniet Group" },
      ],
      invoiceStyle: "280px",
    };
  },
  methods: {
    previewFileAll() {
      this.form.documents = this.$refs.fileInput.files;
      this.disabledShowFile = true;
    },
    save(items) {
      const formData = new FormData();
      formData.set("contractor", this.form.contractor);
      Object.values(this.form.documents).forEach((e, index) => {
        formData.set(`documents[${index}]`, this.form.documents[index]);
      });
      formData.set("type", this.form.type);
      formData.set("vatRate", this.form.vatRate);
      for (let i = 0; i < items.length; i++) {
        for (let key of Object.keys(items[i])) {
          formData.set(`items[${i}][${key}]`, items[i][key]);
        }
      }

      this.$api
        .post("/web/add-purchase", formData)
        .then(() => {
          this.$toast.success("Успешно!");
          this.$store.dispatch("getPurchaseList");
          this.$router.push("/database/purchases");
        })
        .catch((e) => {
          this.$store.commit("setToastData", {
            text: e.response.data.message,
            position: "error",
          });
          this.$toast.error(<toast-block />);
          if (
            e.message.split(" ").reverse()[0] === 500 ||
            e.message.split(" ").reverse()[0] === 401
          ) {
            this.$router.push(`/error/${e.message.split(" ").reverse()[0]}`);
          }
        });
    },
  },
};
</script>

<style scoped>
.modal-container {
  width: 63%;
  margin-left: 20%;
  border-radius: 20px;
  background-color: #ffffff;
}
</style>
